import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { useMicroCopyFi } from "../../hooks/microCopy"
import GetMicroCopy from "../../shared/getMicroCopy"
import * as R from "ramda"
import slugify from "slugify"
import BackLink from "../../components/backLink"
import CollectionHeader from "../../components/collectionHeader"
import CollectionGrid from "../../components/collectionGrid"
import Card from "../../components/card"
import Button from "../../components/button"
import Seo from "../../components/seo"
import LayoutController from "../../components/layoutController"

const ExpoCollectionArticles = ({ data }) => {
  const microCopyTexts = useMicroCopyFi
  const ctaLabel = GetMicroCopy(microCopyTexts, "yleinenLueLisää")
  const content = R.path(["content"], data)
  const expoTitle = R.path(["title"], content)
  const expoSlug = R.path(["slug"], content)
  const mainImage = R.path(["mainImage", "gatsbyImageData"], content)
  const title = R.path(["articlesTitle"], content)
  const text = R.path(["articlesText", "articlesText"], content)
  const articles = R.path(["articles"], content)
  const mainImageSEO = R.path(["mainImage", "file", "url"], content)
  const articlesUrl = `/${expoSlug}/${title}`
  const language = R.path(["node_locale"], content)

  return (
    <LayoutController language={language}> 
      <Seo
        seoTitle={`${title} | ${expoTitle}`}
        seoDesc={text}
        url={typeof window !== "undefined" ? window.location.href : ""}
        image={mainImageSEO}
      />
      <section className="max-w-6xl mt-10 mb-20 px-5 md:mx-auto">
        <div className="mb-8">
          <BackLink label={expoTitle} url={`/${expoSlug}/virtuaalimessut/`} />
        </div>
        <CollectionHeader title={title} text={text} image={mainImage} />
        <div className="mt-12">
          <CollectionGrid>
            {articles.map(article => {
              return (
                <Card
                  key={article.title}
                  title={article.title}
                  image={R.path(["gatsbyImageData"], article.mainImage)}
                  url={`${expoSlug}/${slugify(title, { lower: true })}/${
                    article.slug
                  }`}
                  text={article.contentTextShort}
                  language={language}
                />
              )
            })}
          </CollectionGrid>
        </div>
      </section>
    </LayoutController>
  )
}

export default ExpoCollectionArticles

export const pageQuery = graphql`
  query expoArticlesPage($parentSlug: String) {
    content: contentfulMessu(slug: { eq: $parentSlug }) {
      id
      slug
      node_locale
      title
      mainImage {
        file {
          url
        }
        gatsbyImageData(quality: 60, layout: CONSTRAINED)
      }
      contentTextShort
      articlesTitle
      articlesText {
        articlesText
      }
      articles {
        ... on Node {
          ... on ContentfulArtikkelisivu {
            slug
            title
            contentTextShort
            mainImage {
              gatsbyImageData(layout: CONSTRAINED, width: 400, quality: 80)
            }
          }
        }
      }
    }
  }
`
